/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

     .movie-modal .modal {
        width: 100%;
        height: 100vh;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
    }
    .movie-modal .modal .actions {
        display: flex;
        margin-top: auto;
    }
    .backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .meta-bar {
        flex-direction: column;
    }
    .details, .actions {
        width: 100%!important;
        margin-left: auto;
        text-align: right;
        padding: 25px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
    }
    .shadow {
        display: none;
    }
    .backdrop {
        background: black!important;
        background-image: none!important;
    }
    .backdrop .modal-poster {
        width: 150px;
        z-index: 2;
        position: relative;
        left: auto;
        top: auto;
        box-shadow: 0 0 16px 2px rgb(0 0 0 / 47%);
        padding: 0px;
    }
    .details h1.movie-title {
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .details .description {
        padding: 0;
    }
    h4.movie-tagline {
        text-align: center;
    }
}