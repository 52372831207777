.movie-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 75%);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}
.modal {
    background: rgb(0 0 0 / 70%);
    padding: 0px;
    width: 90%;
    height: 90%;
    border-radius: 25px;
    overflow: hidden;
    max-width: 820px;
    position: relative;
}
.backdrop {
    height: 30%;
    background-size: cover;
    position: relative;
}
.details, .actions {
    width: 60%;
    margin-left: auto;
    text-align: right;
}
.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 75%);
    z-index: 1;
}
.modal-poster {
    width: 250px;
    z-index: 2;
    position: absolute;
    left: 50px;
    top: 50px;
    box-shadow: 0 0 16px 2px rgb(0 0 0 / 47%);
}
.movie-title {
    color: white;
    margin: 0;
    padding: 25px;
    text-align: left;
    padding-left: 0px;
    padding-bottom: 0px;
}
.movie-tagline {
    margin: 0;
    padding: 0px;
    color: lightgray;
    text-align: left;
    opacity: 0.5;
    font-style: italic;
    font-weight: normal;
}
.links {
    display: flex;
    padding: 5px;
}
.link {
    text-decoration: none;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: bold;
}
a#link-trakt {
    background: red;
    color: white;
}
.actions {
    display: flex;
}
