span.genre {
    color: white;
    padding: 1px 20px;
    background: rgb(229 160 13);
    border-radius: 30px;
    font-size: 0.7em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 5px;
}
.genres {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}