.meta-bar {
    display: flex;
    text-align: left;
}
.meta-year {
    color: var(--orange);
    font-weight: bold;
    font-size: 1.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
