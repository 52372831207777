.search-input-container {
    display: flex;
}
.search-input {
    width: 100%;
    padding: 10px;
    font-size: 1.3em;
    margin: 0;
    outline: 0;
    border-radius: 0px;
    border: 0px;
    background: rgb(50 50 50);
    color: white;
    text-transform: capitalize;
}