.categories-container {
  display: flex;
  justify-content: space-between;
}
.category-label.inactive {
  opacity: 0.5;
}
.category-label.active {
  color: var(--orange);
  font-weight: bold;
}
.category-label {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
}
