.movie {
    width: calc(100vw / var(--movieRows));
    display: flex;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 0px;
}
.movie:hover {
    transform: scale(0.95);
    border-radius: 5px;
}