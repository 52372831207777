.request-btn-container {
    display: flex;
    width: 100%;
    padding-right: 10px;
}
button.request-btn {
    width: 100%;
    appearance: none;
    outline: none;
    border: 0px;
    padding: 12px;
    font-size: 1.4em;
}
.already-in-library {
    color: #3c3c3c;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background: white;
}

.request-btn-container .loader-container {
    display: flex;
    transform: scale(0.5);
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}
