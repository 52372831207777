.nothing-found {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    width: 100%;
    box-sizing: border-box;
}
img.nothing-found-image {
    pointer-events: none;
    width: 50%;
}
h1.nothing-found-title {
    font-size: 1.5em;
}