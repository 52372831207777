:root {
    --movieRows: 4;
}
main {
    text-align: center;
    vertical-align: center;
    position: relative;
    background: black;
}

body, html {
    background: black;
}

.modal-open {
/*   height: 100vh;
  overflow-y: hidden; 
    position: fixed;
    */
}