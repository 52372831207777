.close-modal {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    z-index: 10;
    cursor: pointer;
    padding: 10px;
}
.close-modal:hover {
    color: gray;
}
